.custom-select2{
    text-align: end;
    margin: auto;
}
.custom_doughnut{
    font-size: small;
}

.custom-row-chart{
    justify-content: center;
}