.custom-icon-add{
    color: white;
    background-color: green;
    border-radius: 15px;
    width: 25px;
}

.custom-icon-add:hover{
    color: #f8a91c;
    width: 26px;
}

.custom-icon-delete{
    color: red;
    width: 25px;
}

.custom-icon-delete:hover{
    color: #7f7f7f;
    background-color: aliceblue;
}

.icon-default-add{
    color: whitesmoke;
    width: 20px;
}

.icon-default-add:hover{
    color: #f8a91c;
}

.custom-icon-edit {
    color: #456e93;
    width: 25px;
}

.custom-icon-edit:hover {
    color: black;
}
