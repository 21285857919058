body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
    font-size: 16px;
    color: #8a8383;
    background-color: #7f7f7f20;
}

.login{
    margin-top: 50px;
}
.logo{
    margin-top: 20px;
    margin-bottom: 20px;
}
.label-title{
    color: #7f7f7f;
    margin-bottom: 20px;
}

.input-username {
    width: 300px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: auto;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
}

.label-username {
    left: 44% !important;
}

.icon-username{
    width: 20px;
}

.input-password {
    width: 300px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: auto;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
}

.label-password {
    left: 42% !important;
}

.icon-password{
    width: 20px;
}

.custom-link{
    margin-left: 7%;
}

.custom-submit{
    margin-top: 20px;
    width: 300px;
    background-color: #3b74b9 !important;
}

.custom-submit:hover{
    color: #3b74b9;
    background-color: #c2bbbb !important;
    border-color: #3b74b9 !important;
}

.custom-button-row{
    display: block;
}

.errorMessage{
    margin-top: 2%;
    color: red;
    font-weight: 600;
    font-size: 13px;
}

.successMessage{
    margin-top: 2%;
    color: green;
    font-weight: 600;
    font-size: 13px;
}

.infoMessage{
    margin-top: 2%;
    color: #3b74b9;
    font-weight: 600;
    font-size: 13px;
}

.custom-btn-submit{
    text-align: center;
}

.custom-group-col{
    display: flex;
}

.password-toggle-button{
    background-color: transparent !important;
    color: black !important;
    border: none;
    margin-left: -40px;
}

.password-toggle-button2 {
    margin-left: 100px;
    color: black !important;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }
/* styles.css for pdf print*/

.page {
  padding: 5mm;
}

.header {
  text-align: center;
  margin-bottom: 5mm;
}

.logo {
  width: 40mm;
  height: auto;
  margin-bottom: 3mm;
}

.infoLabel {
  font-size: 8pt;
  margin-bottom: 2mm;
}

.tableHeaderCell {
  font-size: 8pt;
  font-weight: bold;
  padding-bottom: 1mm;
}

.tableRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1pt solid #000;
  padding-bottom: 2mm;
  padding-top: 2mm;
  align-items: center;
}

.tableCell {
  flex: 1 1;
  font-size: 8pt;
}

.footer {
  text-align: right;
  margin-top: 5mm;
}

.smallText {
  font-size: 8pt;
}

.pageBreak {
  flex-basis: 100%;
  height: 0;
}

.tableRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1pt solid #000;
  padding-bottom: 2mm;
  padding-top: 2mm;
  align-items: center;
}

.tableHeaderCell {
  font-size: 8pt;
  font-weight: bold;
  padding-bottom: 1mm;
}

.tableCell {
  flex: 1 1;
  font-size: 8pt;
}
.custom-col {
    display: block;
}

.custom-input-search{
    width: 300px;
    font-size: 14px;
}

.custom-button-search{
    width: auto;
}

.icon-search{
    color: #3b74b9;
    width: 25px;
}

.icon-search:hover{
    color: #f8a91c;
    width: 26px;
}

.custom-card-image{
    cursor: pointer;
    width: 75px;
    height: 75px;
    align-self: center;
    margin-top: 5px;
    margin-bottom: -20px;
}

.custom-card-image:hover{
    opacity: 0.8;
}

.custom-card-body{
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}

.custom-card-body:hover{
    font-weight: 600;
    color: #3b74b9;
}

.custom-card-body2{
    cursor: pointer;
    font-size: 13px;
    height: 120px;
    color: aliceblue;
    display: flex;
    position: inherit;
    padding: 10px;
    background-color: #7f7f7f;
}

.custom-card-body2:hover{
    background-color: #7f7f7f3d;
    color: #3b74b9;
}

.custom-card-body2-selected {
    cursor: pointer;
    font-size: 13px;
    height: 120px;
    color: aliceblue;
    display: flex;
    position: inherit;
    padding: 10px;
    background-color: #3b74b9;
}

.custom-card-body2-selected:hover {
    background-color: #7f7f7f3d;
    color: #3b74b9;
}



.custom-p{
    font-size: 14px;
    color: green;
}

.custom-card-title{
    color: inherit;
    font-size: 13px;
    display: flex;
    float: right;
}

.custom-card-title:hover{
    font-weight: 600;
}

.custom-card-image2{
    cursor: pointer;
    width: auto;
    height: 100px;
}

.custom-card-image2:hover{
    width: 98%;
    opacity: 0.8;
}

.custom-small-text{
    display: contents;
    font-size: 12px;
}

.custom-row-head{
    display: grid;
    justify-content: right;
}

.custom-row-head2{
    display: grid;
    justify-content: right;
}

.custom-date-label{
    color: #456e93;
}

.icon-logout{
    width: 20px;
    color: red;
}

.custom-icon{
    width: 20px;
}

.custom-icon-plus{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-plus:hover{
    color: #ffffff;
    background-color: green;
    border-radius: 10px;
}

.custom-icon-minus{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-minus:hover{
    color: #ffffff;
    background-color: #7f7f7f;
    border-radius: 10px;
}

.custom-icon-delete{
    width: 20px;
    color: red;
}

.custom-icon-delete:hover{
    color: #ffffff;
    background-color: red;
    border-radius: 10px;
}

.button-logout{
    border: none;
}

.custom-check1{
    display: contents;
    font-size: small;
    margin-top: 2px;
}

.custom-label-bill{
    display: contents;
    font-size: small;
}

.custom-quote-label{
    float: right;
    margin-right: 10%;
}

.custom-quote-label2{
    float: right;
    margin-right: 10%;
    color: #3b74b9;
    font-weight: 800;
    font-size: large;
}

.custom-print-icon{
    float: right;
    color: #3b74b9;
}

.custom-print-icon:hover{
    color: #7f7f7f;
}

.custom-btn-crash{
    color: red;
}

.custom-btn-crash:hover{
    color: #7f7f7f;
}

.custom-link-button {
    background-color: #3b74b9;
}

.custom-nav{
    margin: auto;
}

.custom-selected{
    background-color: #3b74b9;
    color: aliceblue;
}

.custom-selected:hover{
    background-color: #7f7f7f40;
}

.insuffisant{
    color: #f8a91c;
    font-weight: 800;
}

.correct{
    color: green;
    font-weight: 800;
}

.incorrect{
    color: red;
    font-weight: 800;
}

.icon-cart{
    color: #ffffff;
    width: 16px;
}

.icon-cart:hover{
    color: #7f7f7f;
}
.icon-default{
    color: #3b74b9;
    width: 25px;
}

.icon-default:hover{
    color: #f8a91c;
}

.icon-info-user{
    color: #3b74b9;
    width: 18px;
}

.icon-info-user:hover{
    color: #f8a91c;
}
.custom-icon-proceed{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-proceed:hover{
    color: green;
    width: 22px;
}

.custom-icon-download{
    color: #456e93;
    width: 25px;
}

.custom-icon-download:hover{
    color: green;
    width: 26px;
}
.custom-icon-proceed{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-proceed:hover{
    color: green;
    width: 22px;
}

.custom-icon-download{
    color: #456e93;
    width: 25px;
}

.custom-icon-download:hover{
    color: green;
    width: 26px;
}

.custom-select2{
    text-align: end;
    margin: auto;
}
.custom_doughnut{
    font-size: small;
}

.custom-row-chart{
    justify-content: center;
}
.custom-text-span{
    font-style: oblique;
    font-weight: 800;
}

.custom-btn-add{
    background-color: #3b74b9;
    border: none;
}

.custom-btn-add:hover{
    background-color: green;
}

.custom-btn-all{
    background-color: #58697d;
    border: none;
}

.custom-btn-all:hover{
    background-color: white;
    color: #3b74b9;
}

.custom-btn-active{
    background-color: red;
    border: none;
}

.custom-btn-active:hover{
    background-color: white;
    color: red;
}

.custom-btn-completed{
    background-color: #3b74b9;
    border: none;
}

.custom-btn-completed:hover{
    background-color: white;
    color: green;
}


.custom-icon-add{
    color: white;
    background-color: green;
    border-radius: 15px;
    width: 25px;
}

.custom-icon-add:hover{
    color: #f8a91c;
    width: 26px;
}

.custom-icon-delete{
    color: red;
    width: 25px;
}

.custom-icon-delete:hover{
    color: #7f7f7f;
    background-color: aliceblue;
}

.icon-default-add{
    color: whitesmoke;
    width: 20px;
}

.icon-default-add:hover{
    color: #f8a91c;
}

.custom-icon-edit {
    color: #456e93;
    width: 25px;
}

.custom-icon-edit:hover {
    color: black;
}





