.custom-text-span{
    font-style: oblique;
    font-weight: 800;
}

.custom-btn-add{
    background-color: #3b74b9;
    border: none;
}

.custom-btn-add:hover{
    background-color: green;
}

.custom-btn-all{
    background-color: #58697d;
    border: none;
}

.custom-btn-all:hover{
    background-color: white;
    color: #3b74b9;
}

.custom-btn-active{
    background-color: red;
    border: none;
}

.custom-btn-active:hover{
    background-color: white;
    color: red;
}

.custom-btn-completed{
    background-color: #3b74b9;
    border: none;
}

.custom-btn-completed:hover{
    background-color: white;
    color: green;
}

