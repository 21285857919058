body{
    font-size: 16px;
    color: #8a8383;
    background-color: #7f7f7f20;
}

.login{
    margin-top: 50px;
}
.logo{
    margin-top: 20px;
    margin-bottom: 20px;
}
.label-title{
    color: #7f7f7f;
    margin-bottom: 20px;
}

.input-username {
    width: 300px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: auto;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
}

.label-username {
    left: 44% !important;
}

.icon-username{
    width: 20px;
}

.input-password {
    width: 300px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: auto;
    padding-left: 5% !important;
    padding-right: 5% !important;
    text-align: center;
}

.label-password {
    left: 42% !important;
}

.icon-password{
    width: 20px;
}

.custom-link{
    margin-left: 7%;
}

.custom-submit{
    margin-top: 20px;
    width: 300px;
    background-color: #3b74b9 !important;
}

.custom-submit:hover{
    color: #3b74b9;
    background-color: #c2bbbb !important;
    border-color: #3b74b9 !important;
}

.custom-button-row{
    display: block;
}

.errorMessage{
    margin-top: 2%;
    color: red;
    font-weight: 600;
    font-size: 13px;
}

.successMessage{
    margin-top: 2%;
    color: green;
    font-weight: 600;
    font-size: 13px;
}

.infoMessage{
    margin-top: 2%;
    color: #3b74b9;
    font-weight: 600;
    font-size: 13px;
}

.custom-btn-submit{
    text-align: center;
}

.custom-group-col{
    display: flex;
}

.password-toggle-button{
    background-color: transparent !important;
    color: black !important;
    border: none;
    margin-left: -40px;
}

.password-toggle-button2 {
    margin-left: 100px;
    color: black !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }