.custom-col {
    display: block;
}

.custom-input-search{
    width: 300px;
    font-size: 14px;
}

.custom-button-search{
    width: auto;
}

.icon-search{
    color: #3b74b9;
    width: 25px;
}

.icon-search:hover{
    color: #f8a91c;
    width: 26px;
}

.custom-card-image{
    cursor: pointer;
    width: 75px;
    height: 75px;
    align-self: center;
    margin-top: 5px;
    margin-bottom: -20px;
}

.custom-card-image:hover{
    opacity: 0.8;
}

.custom-card-body{
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}

.custom-card-body:hover{
    font-weight: 600;
    color: #3b74b9;
}

.custom-card-body2{
    cursor: pointer;
    font-size: 13px;
    height: 120px;
    color: aliceblue;
    display: flex;
    position: inherit;
    padding: 10px;
    background-color: #7f7f7f;
}

.custom-card-body2:hover{
    background-color: #7f7f7f3d;
    color: #3b74b9;
}

.custom-card-body2-selected {
    cursor: pointer;
    font-size: 13px;
    height: 120px;
    color: aliceblue;
    display: flex;
    position: inherit;
    padding: 10px;
    background-color: #3b74b9;
}

.custom-card-body2-selected:hover {
    background-color: #7f7f7f3d;
    color: #3b74b9;
}



.custom-p{
    font-size: 14px;
    color: green;
}

.custom-card-title{
    color: inherit;
    font-size: 13px;
    display: flex;
    float: right;
}

.custom-card-title:hover{
    font-weight: 600;
}

.custom-card-image2{
    cursor: pointer;
    width: auto;
    height: 100px;
}

.custom-card-image2:hover{
    width: 98%;
    opacity: 0.8;
}

.custom-small-text{
    display: contents;
    font-size: 12px;
}

.custom-row-head{
    display: grid;
    justify-content: right;
}

.custom-row-head2{
    display: grid;
    justify-content: right;
}

.custom-date-label{
    color: #456e93;
}

.icon-logout{
    width: 20px;
    color: red;
}

.custom-icon{
    width: 20px;
}

.custom-icon-plus{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-plus:hover{
    color: #ffffff;
    background-color: green;
    border-radius: 10px;
}

.custom-icon-minus{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-minus:hover{
    color: #ffffff;
    background-color: #7f7f7f;
    border-radius: 10px;
}

.custom-icon-delete{
    width: 20px;
    color: red;
}

.custom-icon-delete:hover{
    color: #ffffff;
    background-color: red;
    border-radius: 10px;
}

.button-logout{
    border: none;
}

.custom-check1{
    display: contents;
    font-size: small;
    margin-top: 2px;
}

.custom-label-bill{
    display: contents;
    font-size: small;
}

.custom-quote-label{
    float: right;
    margin-right: 10%;
}

.custom-quote-label2{
    float: right;
    margin-right: 10%;
    color: #3b74b9;
    font-weight: 800;
    font-size: large;
}

.custom-print-icon{
    float: right;
    color: #3b74b9;
}

.custom-print-icon:hover{
    color: #7f7f7f;
}

.custom-btn-crash{
    color: red;
}

.custom-btn-crash:hover{
    color: #7f7f7f;
}

.custom-link-button {
    background-color: #3b74b9;
}

.custom-nav{
    margin: auto;
}

.custom-selected{
    background-color: #3b74b9;
    color: aliceblue;
}

.custom-selected:hover{
    background-color: #7f7f7f40;
}

.insuffisant{
    color: #f8a91c;
    font-weight: 800;
}

.correct{
    color: green;
    font-weight: 800;
}

.incorrect{
    color: red;
    font-weight: 800;
}

.icon-cart{
    color: #ffffff;
    width: 16px;
}

.icon-cart:hover{
    color: #7f7f7f;
}
.icon-default{
    color: #3b74b9;
    width: 25px;
}

.icon-default:hover{
    color: #f8a91c;
}

.icon-info-user{
    color: #3b74b9;
    width: 18px;
}

.icon-info-user:hover{
    color: #f8a91c;
}