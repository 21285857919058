/* styles.css for pdf print*/

.page {
  padding: 5mm;
}

.header {
  text-align: center;
  margin-bottom: 5mm;
}

.logo {
  width: 40mm;
  height: auto;
  margin-bottom: 3mm;
}

.infoLabel {
  font-size: 8pt;
  margin-bottom: 2mm;
}

.tableHeaderCell {
  font-size: 8pt;
  font-weight: bold;
  padding-bottom: 1mm;
}

.tableRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1pt solid #000;
  padding-bottom: 2mm;
  padding-top: 2mm;
  align-items: center;
}

.tableCell {
  flex: 1;
  font-size: 8pt;
}

.footer {
  text-align: right;
  margin-top: 5mm;
}

.smallText {
  font-size: 8pt;
}

.pageBreak {
  flex-basis: 100%;
  height: 0;
}

.tableRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1pt solid #000;
  padding-bottom: 2mm;
  padding-top: 2mm;
  align-items: center;
}

.tableHeaderCell {
  font-size: 8pt;
  font-weight: bold;
  padding-bottom: 1mm;
}

.tableCell {
  flex: 1;
  font-size: 8pt;
}