.custom-icon-proceed{
    color: #3b74b9;
    width: 20px;
}

.custom-icon-proceed:hover{
    color: green;
    width: 22px;
}

.custom-icon-download{
    color: #456e93;
    width: 25px;
}

.custom-icon-download:hover{
    color: green;
    width: 26px;
}